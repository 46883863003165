export const MODAL_IDS = {
  INSUFFICIENT_FUNDS: 'insufficientFundsModal',
  HIGH_RISK: 'highFiskModal',
  BANK_NOT_READY: 'bankNotReady',
  USER_AGREEMENT: 'userAgreement',
  UNLINK_BILL: 'unlinkBill',
  REPAYMENT_SUCCESS: 'repaymentSuccess',
  REPAYMENT_ERROR: 'repaymentError',
  REPAYMENT_RELINK_ACCOUNT: 'repaymentRelinkAccount',
  BANK_BALANCE_DETAILS: 'bankBalanceDetails',
  CONFIRM_LINK_PAYMENT_CARD: 'confirmLinkPaymentCard',
  SMS_OPT_IN: 'smsOptIn',
  CREDIT_SCORE_INCREASE: 'creditScoreIncrease',
  ACCOUNT_REOPEN_SUCCESS: 'accountReopenSuccess',
  ONBOARDING_CREDIT_SCORE_DISCLAIMER: 'onboardingCreditScoreDisclaimer',
  PERSONAL_INFORMATION_EMAIL: 'personalInformationEmail',
  PRIME_GUARANTEE_MODAL: 'primeGuaranteeModal',
  LINK_BANK_ACCOUNT_EDUCATION: 'linkBankAccountEducation',
  DELETE_DOCUMENT: 'deleteDocument',
  REFER_FRIEND: 'referFriend',
  UPGRADE_MEMBERSHIP_MODAL: 'upgradeMembershipModal',
  TOOLTIP_MODAL: 'tooltipModal',
  LEARN_MORE_MODAL: 'learnMoreModal',
  REWARD_POINTS_REDEEM_SUCCESS: 'rewardPointsRedeemSuccess',
  CLOSE_GOAL: 'closeGoal',
  RENAME_GOAL: 'renameGoal',
  FIRST_BILL_LINKED: 'firstBillLinked',
  ONBOARDING_FINISHED: 'onboardingFinishedModal',
  NATIVE_APP_LAUNCH: 'nativeAppLaunch',
  DOB_MODAL: 'dobModal',
}

export const MODAL_HASH_BASED_IDS = {
  SOCURE_DOCUMENTS_UPLOAD: '/socure-documents-upload',
  MX_BANK_CONNECT: '/mx-bank-connect',
  ACCOUNT_TYPE_SELECT: '/account-type-select',
  CARD_ACTIVATION_SUCCESS: '/card-activation-success',
  HIGHNOTE_TERMS_AND_CONDITIONS: '/highnote-terms-and-conditions',
  LAUNCH_MOBILE_APP: '/launch-mobile-app',
  ACTIVATE_HIGHNOTE_CARD: '/activate-highnote-card'
}
